<template>
  <Layout />
</template>

<script>
import { getToken } from '@/utils/Token';

import Layout from '@/components/layout/Layout.vue';

export default {
  name: 'Index',
  components: {
    Layout
  },
  created() {
    const userToken = getToken();
    this.$store.commit('User/updateToken', userToken);
    this.$store.dispatch('Common/getMenu');
    this.$store.dispatch('User/getUserInfo');
    this.$store.dispatch('User/getLinks');
    this.$store.dispatch('Common/getJobs');
    this.$store.dispatch('Common/getOrgTree');
    this.$store.dispatch('Common/getStoreType');
    this.$store.dispatch('Common/getPermissionList');
  }
};
</script>

<style scoped lang="scss">
</style>
