<template>
  <div class="app-nav">
    <a-menu class="menu"
            v-model="currentKey"
            :open-keys.sync="keysOpened"
            mode="inline"
            @openChange="openNav"
            @click="selectItem">
      <a-sub-menu v-for="nav of navs" :key="nav.id">
        <span slot="title"><a-icon type="home"/><span>{{ nav.name }}</span></span>
        <template v-if="nav.children && nav.children.length">
          <a-menu-item v-for="item in nav.children"
                       :key="item.id">
            {{ item.name }}
          </a-menu-item>
        </template>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>

export default {
  name: 'Nav',
  props: {
    navs: { type: Array, default: () => [] },
  },
  watch: {
    navs(list) {
      if (!list || !list.length) {
        return;
      }
      this.keysOpened = list.map(i => i.id);
      this.update(this.$route?.path || '');
    },
    '$route.path'() {
      this.update(this.$route?.path || '');
    },
  },
  data() {
    return {
      keysOpened: [''],
      currentKey: [''],
      parentKey: [''],
    };
  },
  methods: {
    openNav(openKeys) {
      this.keysOpened = openKeys;
    },
    update(path) {
      this.currentKey = [this.getIdByPath(path, [].concat(...this.navs))];
      this.parentKey = [this.getParentIdByPath(path, [].concat(...this.navs))];
    },
    getIdByPath(path, navs) {
      for (let i = 0; i < navs.length; i++) {
        if (navs[i]?.children?.length) {
          navs.push(...navs[i].children);
        }
        const baseRoute = this.getFunctionBaseRoute(navs[i].jump_url);
        if (path.includes(baseRoute) || `${path}/` === baseRoute) {
          return navs[i].id;
        }
      }
      return '';
    },
    getParentIdByPath(path, navs) {
      for (let i = 0; i < navs.length; i++) {
        if (navs[i].children && navs[i].children.length) {
          navs.push(...(navs[i].children.map((nav) => ({ ...nav, parentId: navs[i].id }))));
        }
        if (path.includes(navs[i].jump_url)) {
          return navs[i].parentId;
        }
      }
      return '';
    },
    getParentIdById(id, navs) {
      for (let i = 0; i < navs.length; i++) {
        if (navs[i].children && navs[i].children.length) {
          navs.push(...(navs[i].children.map((nav) => ({ ...nav, parentId: navs[i].id }))));
        }
        if (navs[i].id === id) {
          return navs[i].id;
        }
      }
      return '';
    },
    getPathById(id, navs) {
      for (let i = 0; i < navs.length; i++) {
        if (navs[i].children && navs[i].children.length) {
          navs.push(...navs[i].children);
        }
        if (navs[i].id === id) {
          return navs[i].jump_url;
        }
      }
    },
    selectItem({ key, keyPath }) {
      if (this.$route.path === this.getPathById(key, [].concat(...this.navs))) {
        return;
      }
      this.currentKey = [key];
      const path = this.getPathById(key, [].concat(...this.navs));
      this.$emit('select', { key, keyPath, path });
    },

    /**
     * 获取某一功能的基础路由字符串，格式：/index/statistics/certificate/offline
     * @param route 属于某一页面的路由字符串
     * @returns {string}
     */
    getFunctionBaseRoute(route) {
      if (!route) {
        return;
      }
      const routes = route.split('/').filter((i) => !!i);
      // 兼容部分配置不合理的路由
      const compatible3 = [
        'index/statistics/skillDetail',
        'index/statistics/groupDetail',
        'index/statistics/certificate',
        'index/statistics/course',
        'index/offline/training',
        'index/offline/category',
        'index/offline/form',
        'index/offline/signApproval',
        'index/offline/orderApproval',
        'index/offline/teacher',
        'index/offline/trainPlanExport',
        'index/advertising/sticker',
        'index/advertising/banner',
      ];
      const stage = compatible3.includes(routes.slice(0, 3).join('/')) ? 3 : 4;
      return `/${
        routes.slice(0, stage).join('/')
      }/`;
    },
  },
};
</script>

<style scoped lang="scss">
.app-nav {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 5px);
  border-radius: 2px;
  background-color: $light01;

  &::-webkit-scrollbar {
    width: 6px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background: #EDEDED;
  }

  .menu {
    border-right-width: 0;
  }
}
</style>
