<template>
  <div id="app" class="app-root">
    <a-config-provider :locale="locale">
      <router-view/>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  name: 'app',
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style lang="scss" scoped>
.app-root {
  min-height: 100%;
  height: 100%;
  //min-width: 1200px;
  background-color: #FFFFFF;
}
</style>
