import request from '@/utils/RequestNoLimits';


//获取模板
export function userimportFile (params) {
  return request({
    url: `/datacenter/admin/import/userimport`,
    method: 'post',
    data: params,
  });
}
 // /datacenter/admin/import/userimport

//模板筛选
export function getScreennew (params) {
  return request({
    url: `/datacenter/admin/import/userimport/screen`,
    method: 'get',
    params,
  });
}


//模板删除
export function deleteDelnew (params) {
  return request({
    url: `/datacenter/admin/import/userimport/del`,
    method: 'delete',
    data: params,
  });
}