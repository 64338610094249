import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';
//timeout: 300000, //去除时间限制
import request from '@/utils/RequestNoLimits';
import { downloadFileExtra } from '@/utils/JsDownload';
import moment from 'moment';
// 学员明细--完训统计
export function getParticipationStudentFinish (params) {
  let url = "/participation/admin/student/noexam";
  if (params.end_time === moment().format('YYYY-MM-DD')) {
    url = '/participation/admin/student/noexam';
  } else {
    url = '/participation/admin/student/noexamFixed';
  }
  return request({
    url,
    method: 'get',
    params,
  });
}

// 学员明细--完训统计 - 下载
export function getParticipationStudentFinishDownload (params) {
  let url = "/participation/admin/student/noexam";
  if (params.end_time === moment().format('YYYY-MM-DD')) {
    url = '/participation/admin/student/noexam';
  } else {
    url = '/participation/admin/student/noexamFixed';
  }
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 学员明细--考试统计
export function getParticipationStudentExam (params) {
  let url = "/participation/admin/student/exam";
  if (params.end_time === moment().format('YYYY-MM-DD')) {
    url = '/participation/admin/student/exam';
  } else {
    url = '/participation/admin/student/examFixed';
  }
  return request({
    url,
    method: 'get',
    params,
  });
}

// 学员明细--考试统计 - 下载
export function getParticipationStudentExamDownload (params) {
  let url = "/participation/admin/student/exam";
  if (params.end_time === moment().format('YYYY-MM-DD')) {
    url = '/participation/admin/student/exam';
  } else {
    url = '/participation/admin/student/examFixed';
  }
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 学员明细--参训统计
export function getParticipationStudentParticipate (params) {
  let url = "/participation/admin/student/finish";
  if (params.end_time === moment().format('YYYY-MM-DD')) {
    url = '/participation/admin/student/finish';
  } else {
    url = '/participation/admin/student/finishFixed';
  }
  return request({
    url,
    method: 'get',
    params,
  });
}

// 学员明细--参训统计 - 下载
export function getParticipationStudentParticipateDownload (params) {
  let url = "/participation/admin/student/finish";
  if (params.end_time === moment().format('YYYY-MM-DD')) {
    url = '/participation/admin/student/finish';
  } else {
    url = '/participation/admin/student/finishFixed';
  }
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
